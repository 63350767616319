<template>
    <div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
        <div class="kt-footer__copyright">
            <img
                style="width: 7%;margin-top: -8px;margin-bottom: -8px;"
                src="/assets/ale/logo.png"
            />
            <a
                v-if="this.releasedFor == 'ALE'"
                href="https://play.google.com/store/apps/details?id=com.ale.omniaccessassettracking"
                target="_blank"
                rel="noopener"
                class="kt-footer__menu-link"
                style="margin-right: 10px;"
            ><img
                style=" width: 90px; margin: -18px 0px -17px 10px; "
                src="/assets/ale/google-play-badge.png"
            /></a>
            © {{ currentYear }} {{ this.releasedFor == "HAN" ? "HAN Networks" : "Alcatel-Lucent Enterprise" }}
        </div>
        <div class="kt-footer__menu">

            <a
                v-if="this.releasedFor == 'ALE'"
                href="/doc/omniaccess-stellar-asset-tracking-data-privacy-notice-en.pdf"
                target="_blank"
                rel="noopener"
                class="kt-footer__menu-link kt-link"
            >{{ $t("main_privacy") }}</a
            >
            <a
                v-else
                href="http://www.han-networks.com/low-y.php"
                target="_blank"
                rel="noopener"
                class="kt-footer__menu-link kt-link"
            >{{ $t("main_privacy") }}</a
            >

            <a
                v-if="this.releasedFor == 'ALE'"
                href="/doc/omniaccess_stellar_at_terms.pdf"
                target="_blank"
                rel="noopener"
                class="kt-footer__menu-link kt-link"
            >{{ $t("main_legal") }}</a>
            <a
                v-else
                href="http://www.han-networks.com/low-u.php"
                target="_blank"
                rel="noopener"
                class="kt-footer__menu-link kt-link"
            >{{ $t("main_legal") }}</a>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            releasedFor: process.env.VUE_APP_RELEASED_FOR,
            currentYear: new Date().getFullYear()
        };
    }
};
</script>

<style scoped>
.alePng {
	width: 110px;
	margin-right: 10px;
}
</style>
